import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const imgSrc =
  "https://cdn.sanity.io/images/pm1b01pt/production/41fad3ef5b462744092612c8f2b539be654ddaa9-6923x4122.jpg?w=1920&h=1143&fit=crop";

const SEO = ({ title, description, url, type, image }) => (
  <Helmet>
    <title>{title}</title>
    <link rel="canonical" href={url} />
    <meta name="description" content={description} />
    <meta
      property="og:url"
      content={url ? url : "https://www.clips-hairstudio.com/"}
    />
    <meta property="og:type" content={type ? type : "website"} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image ? image : imgSrc} />
  </Helmet>
);

export default SEO;

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string,
};
